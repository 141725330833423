import React, { useEffect, useRef, useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import { Form, Stack } from 'react-bootstrap';
import { adyenEnvironment, adyenClientKey } from '../../config/environment';
import { AppContext } from '../../App';
import api from '../../services/endpoints';
import history from '../../utils/history';
import styled from '@emotion/styled';
import { DarkLogo, SubSectionTitle } from '../../components';
import { SUCCESS_PAYMENT_STATUSES } from './constants';
import '@adyen/adyen-web/dist/adyen.css';
import './styles.css';
import ReactGA from 'react-ga4';
import { GreenButton } from '../../components';
import { toast, ToastContainer } from 'react-toastify';

const toastSettings = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

const SectionWrapper = styled.section({
  maxWidth: 1170,
  margin: '0 auto',
  width: '60%',
  marginBottom: '50px',
  paddingTop: 70,
  '> div:first-of-type': {
    '@media (max-width: 991px)': {
      maxWidth: 160,
      position: 'relative',
      left: 0,
      margin: '0 auto 50px',
    },
  },
  '@media (max-width: 991px)': {
    width: '90%',
  },
});

const PaymentFormWrapper = styled.section(({ theme }) => ({
  width: '70%',
  maxWidth: 1270,
  margin: '0 auto',
  padding: 30,
  backgroundColor: theme.colors.background,
  borderRadius: '25px',
  minHeight: '50%',
  '@media (max-width: 700px)': {
    width: '95%',
    padding: 10,
  },
  '@media (max-width: 991px)': {
    width: '90%',
  },
}));

const Text = styled.p(({ theme }) => ({
  fontSize: 18,
  lineHeight: '28px',
  span: {
    backgroundColor: theme.colors.attention,
  },
  '@media (max-width: 700px)': {
    fontSize: 14,
  },
}));

const Ul = styled.ul(({ theme }) => ({
  listStyleType: '"- "',
  span: {
    backgroundColor: theme.colors.attention,
  },
  '@media (max-width: 700px)': {
    fontSize: 14,
  },
}));

const BoldText = styled.h3(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: 20,
  lineHeight: '33px',
  '@media (max-width: 700px)': {
    fontSize: 24,
  },
}));

export const PaymentPage = () => {
  const ref = useRef();
  const { state, dispatch } = React.useContext(AppContext);
  const [isTermsConfirmed, setIsTermsConfirmed] = useState(false);
  const [code, setCode] = useState('');
  const isProgram = sessionStorage.getItem('isPreventionProgram');
  const getUserData = async () => {
    const { status, data } = await api.user.getProfile();
    if (status === 200) {
      dispatch({
        type: 'UPDATE',
        payload: {
          user: data,
        },
      });
    }
  };
  const redeemCode = () => {
    const formSubmitPromise = api.payment.redeemCode(code).then((res) => {
      if (res.data) {
        api.user.removeUserTag([{ name: 'PREV_WAITING_FOR_PAYMENT', source: 'prevention landing page registration' }], state.user.keycloakUuid);
        ReactGA.event({
          category: 'prevention registration',
          action: 'Discount code redeem successful',
        });
        api.cube.addPreventionCubeToUser({ cubeId: state.chosenCubeId });
        setTimeout(() => history.push(state.isProgram ? '/praevention-program-confirmation' : '/order-confirmation'), 2500);
        toast.dismiss();
      } else {
        throw 'wrong code';
      }
    });
    toast.promise(formSubmitPromise, {
      pending: {
        render() {
          return 'Anfrage wird gesendet...';
        },
        ...toastSettings,
      },
      error: {
        render() {
          return 'Der Gutschein konnte nicht eingelöst werden. Bitte kontrolliere den Gutscheincode.';
        },
        ...toastSettings,
      },
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const temporaryToken = sessionStorage.getItem('temporaryToken');
    if (!token && !temporaryToken) return history.push('/login');
    if (!state.chosenCubeId) return history.push(isProgram ? '/praevention' : '/');
    if (!state.user) return getUserData();
    if (!isTermsConfirmed && !isProgram) return;
    const payload = {
      userUuid: state.user.keycloakUuid,
      email: state.user.userDetail?.email,
      returnUrl: window.location.origin,
      amount: {
        value: state.chosenCubePrice,
        currency: 'EUR',
      },
      countryCode: 'AT',
      products: [
        {
          productId: state.chosenCubeId,
          type: 'cube',
          price: state.chosenCubePriceNumber,
          productName: state.chosenCubeName,
          itemNumber: state.chosenCubeNumber,
        },
      ],
    };

    return api.payment
      .createPaymentSession(payload)
      .then((res) => {
        return AdyenCheckout(createConfiguration(res.data));
      })
      .then((checkout) => {
        const cardComponent = checkout.create('dropin').mount(ref.current);
        ReactGA.event({
          category: 'prevention registration',
          action: 'Adyen payment session created',
        });
      })
      .catch((err) => console.log('ERR===>>', err));
  }, [state.user, isTermsConfirmed]);

  const createConfiguration = (res) => {
    const configuration = {
      environment: adyenEnvironment,
      clientKey: adyenClientKey,
      session: {
        id: res.id,
        sessionData: res.sessionData,
      },
      onPaymentCompleted: ({ resultCode }, comp) => {
        if (state.isProgram && !SUCCESS_PAYMENT_STATUSES.includes(resultCode)) {
          setTimeout(() => history.push('/praevention'), 2500);
          api.user.checkTagAndDeleteUser();
          ReactGA.event({
            category: 'prevention registration',
            action: 'new user deleted because payment failed',
          });
          return;
        }
        if (!SUCCESS_PAYMENT_STATUSES.includes(resultCode)) {
          setTimeout(() => history.push('payment-failed'), 2500);
          return;
        }
        api.user.removeUserTag([{ name: 'PREV_WAITING_FOR_PAYMENT', source: 'prevention landing page registration' }], state.user.keycloakUuid);
        ReactGA.event({
          category: 'prevention registration',
          action: 'Adyen payment successful',
        });
        setTimeout(() => history.push(state.isProgram ? '/praevention-program-confirmation' : '/order-confirmation'), 2500);
      },
      onError: (error, component) => {
        if (state.isProgram) {
          api.user.checkTagAndDeleteUser();
          ReactGA.event({
            category: 'prevention registration',
            action: 'new user deleted because payment failed',
          });
        }
        console.error(error);
      },
    };
    return configuration;
  };

  return (
    <>
      <SectionWrapper>
        <div style={{ width: '200px', marginBottom: '30px', position: 'relative', right: '50px' }}>
          {!state.isProgram && <DarkLogo settings={{ cursor: 'pointer' }} onClick={() => history.push(state.isProgram ? '/praevention' : '')}></DarkLogo>}
        </div>
        <SubSectionTitle>nur noch ein klick und du kannst loslegen</SubSectionTitle>
        <BoldText>{state.chosenCubeName}</BoldText>
        <Ul>
          <li>
            Du zahlst zunächst {state.chosenCubePriceNumber.toString().replace('.', ',')}€ inkl. MwSt (19%). <span>Am Ende des Kurses erstattet dir deine Krankenkasse bis zu 100% der Kosten!</span>
          </li>
          <li>Nach § 20 Abs. 1 SGB V von allen gesetzlichen Krankenkassen zertifiziert</li>
          <li>Von Experten entwickelt</li>
          <li>
            <span>Sofortiger Zugang nach Zahlung</span>
          </li>
        </Ul>
        {!isProgram && (
          <Form.Group className='mb-3 mt-5'>
            <Form.Check
              label={
                <Form.Label>
                  <p className='checkbox-label' style={{ marginBottom: 10 }}>
                    Ich stimme der Ausführung des Vertrages vor Ablauf der Widerrufsfrist ausdrücklich zu. Ich habe zur Kenntnis genommen, dass das Widerrufsrecht mit Beginn der Ausführung des
                    Vertrags erlischt.
                  </p>
                  <p className='checkbox-label'>
                    Durch deinen Kauf erklärst du dich mit den{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open(`${state.isProgram ? '/praevention' : ''}/agbs`, '_blank')}>
                      AGB
                    </span>{' '}
                    und{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open(`${state.isProgram ? '/praevention' : ''}/datenschutzerklarung`, '_blank')}>
                      Datenschutzrichtlinien einverstanden.
                    </span>
                  </p>
                </Form.Label>
              }
              onChange={(e) => setIsTermsConfirmed(e.target.checked)}
              checked={isTermsConfirmed}
              disabled={isTermsConfirmed}
              tabIndex='7'
            />
          </Form.Group>
        )}
      </SectionWrapper>
      {(isTermsConfirmed || isProgram) && (
        <PaymentFormWrapper>
          {isProgram && (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Form.Group>
                <Form.Label>Gutscheincode</Form.Label>
                <Stack direction='horizontal'>
                  <Form.Control className='m-2' value={code} onChange={(e) => setCode(e.target.value)}></Form.Control>
                  <GreenButton onClick={() => redeemCode()} text='Einlösen' style={{ width: '120px', height: '40px', lineHeight: '40px', fontSize: '14px' }}></GreenButton>
                </Stack>
              </Form.Group>
            </Form>
          )}
          <BoldText>Zahlungsoptionen</BoldText>
          <div>
            <div id='dropin-container' className='dropin-dark' ref={ref}></div>
          </div>
        </PaymentFormWrapper>
      )}
      <ToastContainer />
    </>
  );
};
